import type { ISubMenu } from '@/components/sidebar/data';
import { Urls } from '@/lib/urls';

import {
  AdjustmentsHorizontalIcon,
  ArchiveBoxIcon,
  BanknotesIcon,
  BellIcon,
  BookOpenIcon,
  BriefcaseIcon,
  BuildingOffice2Icon,
  CheckIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  CubeIcon,
  CubeTransparentIcon,
  DocumentArrowUpIcon,
  DocumentCheckIcon,
  EnvelopeIcon,
  FingerPrintIcon,
  HomeModernIcon,
  InformationCircleIcon,
  ListBulletIcon,
  LockOpenIcon,
  PuzzlePieceIcon,
  ReceiptPercentIcon,
  RectangleGroupIcon,
  ShieldCheckIcon,
  ShoppingBagIcon,
  Square2StackIcon,
  Square3Stack3DIcon,
  Squares2X2Icon,
  SquaresPlusIcon,
  TagIcon,
  TruckIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/16/solid';
import {
  DimensionConfigurationConfig,
  type ModelName,
} from '@pigello/pigello-matrix';

type GroupType =
  | 'admin'
  | 'globalconfiguration'
  | 'security'
  | 'modulesettings';

interface IMenuGroup {
  title: string;
  isIndex?: boolean;
  key: string;
  subMenus: ISubMenu[];
}

export type SettingsMenu = Record<GroupType, IMenuGroup>;

function getSubItemsModelNames<T extends Array<ISubMenu>>(
  subItems: T,
  // Only pick out modelname from objects where the field is of type ModelName,
  // then we index into those to get a union type of all model names used
  skipModels?: Array<
    | Extract<T[number], { modelNames: ModelName[] }>['modelNames'][number]
    | Extract<T[number], { modelName: ModelName }>['modelName']
  >
): ModelName[] {
  const summedModelNames: ModelName[] = [];

  const checkModelName = (modelName: ModelName | undefined) => {
    if (!modelName) return;

    if (skipModels && skipModels.includes(modelName)) return;

    summedModelNames.push(modelName);
  };

  for (const item of subItems) {
    checkModelName(item.modelName);

    if (item.modelNames)
      for (const modelName of item.modelNames) {
        checkModelName(modelName);
      }
  }

  return summedModelNames;
}

export const contractCancellationsRouteData: ISubMenu[] = [
  {
    title: 'Inställningar',
    key: 'settings',
    path: Urls.settings.modules.base.contractcancellations.settings,
    icon: <Cog6ToothIcon className='mr-2 size-4' />,
  },
  {
    title: 'Anledningar',
    key: 'reasons',
    path: Urls.settings.modules.base.contractcancellations.reasons,
    icon: <ListBulletIcon className='mr-2 size-4' />,
  },
];

export const accountingRouteData: ISubMenu[] = [
  {
    title: 'Generella inställningar',
    key: 'general',
    path: Urls.settings.modules.invoicing.accounting.general,
    icon: <Cog6ToothIcon className='mr-2 size-4' />,
  },
  {
    title: 'Bokföringsmetod',
    key: 'method',
    path: Urls.settings.modules.invoicing.accounting.method,
    icon: <AdjustmentsHorizontalIcon className='mr-2 size-4' />,
  },
  {
    title: 'Kontoplan',
    key: 'accounts',
    path: Urls.settings.modules.invoicing.accounting.accounts,
    icon: <ArchiveBoxIcon className='mr-2 size-4' />,
  },
  {
    title: 'Verifikat',
    key: 'verifications',
    path: Urls.settings.modules.invoicing.accounting.verifications,
    icon: <BookOpenIcon className='mr-2 size-4' />,
  },
  {
    title: 'Betalmetoder',
    key: 'payment-methods',
    path: Urls.settings.modules.invoicing.accounting.paymentmethods,
    icon: <BanknotesIcon className='mr-2 size-4' />,
  },
  {
    title: 'Dimensioner',
    key: 'dimensions',
    path: Urls.settings.modules.invoicing.accounting.dimensions,
    icon: <TagIcon className='mr-2 size-4' />,
    modelName: DimensionConfigurationConfig.modelName,
  },
];

export const articlesRouteData: ISubMenu[] = [
  {
    title: 'Hyresartiklar',
    key: 'rent',
    path: Urls.settings.modules.invoicing.articles.default,
    icon: <DocumentCheckIcon className='mr-2 size-4' />,
  },
  {
    title: 'Faktureringsartiklar',
    key: 'invoice',
    path: Urls.settings.modules.invoicing.articles.invoice,
    icon: <ReceiptPercentIcon className='mr-2 size-4' />,
  },
  {
    title: 'Förbrukningsartiklar',
    key: 'consumable',
    path: Urls.settings.modules.invoicing.articles.consumable,
    icon: <ShoppingBagIcon className='mr-2 size-4' />,
  },
  {
    title: 'Artikelöverskrivningar',
    key: 'overridden',
    path: Urls.settings.modules.invoicing.articles.overriddenArticles,
    icon: <InformationCircleIcon className='mr-2 size-4' />,
  },
  {
    title: 'Kategorier',
    key: 'categories',
    path: Urls.settings.modules.invoicing.articles.categories,
    icon: <TagIcon className='mr-2 size-4' />,
  },
];

export const invoicingSettingsRouteData: ISubMenu[] = [
  {
    title: 'Avtalsavisering',
    key: 'contractinvoicing',
    path: Urls.settings.modules.invoicing.invoicingsettings.contractinvoicing,
    icon: <DocumentArrowUpIcon className='mr-2 size-4' />,
  },
  {
    title: 'Betalningsvillkor',
    key: 'paymentterms',
    path: Urls.settings.modules.invoicing.invoicingsettings.paymentterms,
    icon: <CreditCardIcon className='mr-2 size-4' />,
  },
];

export const profileSettingsRouteData: ISubMenu[] = [
  {
    title: 'Användarinformation',
    key: 'settings',
    path: Urls.settings.profile.settings,
    icon: <UserIcon className='mr-2 size-4' />,
    modelName: 'organizationuser',
  },
  /* {
    title: 'Behörigheter',
    key: 'permissions',
    path: '/settings/profile/permissions',
    icon: <ShieldCheckIcon className='mr-2 size-4' />,
    disabled: true,
  }, */
  {
    title: 'Segment',
    key: 'segments',
    path: '/settings/profile/segments',
    icon: <Squares2X2Icon className='mr-2 size-4' />,
    modelName: 'segment',
  },
  {
    title: 'Mina arbetsroller',
    key: 'work-engagements',
    path: '/settings/profile/work-engagements',
    icon: <BriefcaseIcon className='mr-2 size-4' />,
    modelNames: ['workengagement', 'organizationuser'],
  },

  {
    title: 'Notifikationsinställningar',
    key: 'notification-settings',
    path: '/settings/profile/notification-settings',
    icon: <BellIcon className='mr-2 size-4' />,
  },
  /* {
    title: 'Integrationer',
    key: 'integrations',
    path: '/settings/profile/integrations',
    icon: <Squares2X2Icon className='mr-2 size-4' />,
    disabled: true,
  },
  {
    title: 'Händelser',
    key: 'events',
    path: '/settings/profile/events',
    icon: <InboxStackIcon className='mr-2 size-4' />,
    disabled: true,
  }, */
];

export const marketTargetAudiencesData: ISubMenu[] = [
  {
    title: 'Lägenheter',
    key: 'apartment',
    icon: <HomeModernIcon className='mr-1 size-4' />,
    path: Urls.settings.organization.market.targetAudiences.apartment.overview,
    modelName: 'apartmenttargetaudience',
  },
  {
    title: 'Lokaler',
    key: 'industrial-premises',
    icon: <Square2StackIcon className='mr-1 size-4' />,
    path: Urls.settings.organization.market.targetAudiences.industrialPremises
      .overview,
    modelName: 'industrialpremisestargetaudience',
  },
  {
    title: 'Utomhussektioner',
    key: 'outdoor-section',
    icon: <Square3Stack3DIcon className='mr-1 size-4' />,
    path: Urls.settings.organization.market.targetAudiences.outdoorSection
      .overview,
    modelName: 'outdoorsectiontargetaudience',
  },
  {
    title: 'Fordonsplatser',
    key: 'parking-spot',
    icon: <TruckIcon className='mr-1 size-4' />,
    path: Urls.settings.organization.market.targetAudiences.parkingSpot
      .overview,
    modelName: 'parkingspottargetaudience',
  },
];

export const rentableTermsData: ISubMenu[] = [
  {
    title: 'Lägenheter',
    key: 'apartment',
    icon: <HomeModernIcon className='mr-1 size-4' />,
    path: Urls.settings.organization.market.terms.apartment.overview,
    modelName: 'apartmentterms',
  },
  {
    title: 'Lokaler',
    key: 'industrial-premises',
    icon: <Square2StackIcon className='mr-1 size-4' />,
    path: Urls.settings.organization.market.terms.industrialPremises.overview,
    modelName: 'industrialpremisesterms',
  },
  {
    title: 'Utomhussektioner',
    key: 'outdoor-section',
    icon: <Square3Stack3DIcon className='mr-1 size-4' />,
    path: Urls.settings.organization.market.terms.outdoorSection.overview,
    modelName: 'outdoorsectionterms',
  },
  {
    title: 'Fordonsplatser',
    key: 'parking-spot',
    icon: <TruckIcon className='mr-1 size-4' />,
    path: Urls.settings.organization.market.terms.parkingSpot.overview,
    modelName: 'parkingspotterms',
  },
];

const adminSubMenueItems = [
  {
    path: Urls.settings.organization.users.default,
    key: 'users',
    modelName: 'organizationuser',
    detailModalModelName: 'organizationuser',
    title: 'Användare',
    icon: <UsersIcon className='mr-2 size-4' />,
  },
  {
    title: 'Behörighetsgrupper',
    icon: <LockOpenIcon className='mr-2 size-4' />,
    path: Urls.settings.organization.roles.default,
    key: 'roles',
    modelName: 'role',
  },
  {
    title: 'Arbetsroller',
    icon: <BriefcaseIcon className='mr-2 size-4' />,
    path: Urls.settings.organization.workengagements.default,
    key: 'workengagements',
    modelName: 'workengagement',
  },
] as const satisfies ISubMenu[];

const globalConfigurationsSubMenueItems = [
  {
    title: 'Monitorering',
    icon: <SquaresPlusIcon className='mr-2 size-4' />,
    path: Urls.settings.organization.monitoringconfigurations.default,
    key: 'monitoringconfigurations',
    modelName: 'monitoringconfiguration',
  },
  {
    path: Urls.settings.organization.validationconfig.default,
    title: 'Valideringar',
    icon: <CheckIcon className='mr-2 size-4' />,
    key: 'validation-config',
    modelName: 'validationconfiguration',
  },
  {
    path: Urls.settings.organization.customercommunicationconfig.default,
    title: 'Mina Sidor',
    icon: <UsersIcon className='mr-2 size-4' />,
    key: 'customer-communication-config',
    modelName: 'customercommunicationconfiguration',
  },
  {
    path: Urls.settings.organization.tags.default,
    title: 'Taggar',
    icon: <TagIcon className='mr-2 size-4' />,
    key: 'tags',
    modelName: 'tag',
  },
  {
    path: Urls.settings.organization.customercolumntype.default,
    title: 'Anpassade fält',
    // icon: <TagIcon className='mr-2 size-4' />,
    icon: <CubeTransparentIcon className='mr-2 size-4' />,
    modelName: 'customercolumntype',
    key: 'customercolumns',
  },
  {
    path: Urls.settings.organization.organizationconfig.default,
    title: 'Organisationer',
    icon: <BuildingOffice2Icon className='mr-2 size-4' />,
    key: 'organization-config',
    modelName: 'organization',
  },
  {
    title: 'Notiser',
    key: 'globalnotificationdistribution',
    icon: <BellIcon className='mr-2 size-4' />,
    path: Urls.settings.organization.notificationdistributionoverride.default,
  },
  /* {
    path: Urls.settings.organization.workengagements.default,
    title: 'Externa resurser',
    key: 'externalresources',
    disabled: true,
  }, 
  {
    title: 'Branding',
    path: '/branding',
    disabled: true,
    key: 'branding',
    icon: <PaintBrushIcon className='mr-2 size-4' />,
  },
  {
    title: 'Övrigt',
    path: '/other',
    disabled: true,
    key: 'other',
    icon: <BellIcon className='mr-2 size-4' />,
  }, */
  {
    title: 'Integrationer',
    icon: <PuzzlePieceIcon className='mr-2 size-4' />,
    path: Urls.settings.organization.integrations,
    key: 'allintegrations',
    modelName: 'integrationuseragent',
  },
] as const satisfies ISubMenu[];

const securitySubMenuItems = [
  {
    path: Urls.settings.organization.securityconfig.default,
    title: 'Inloggning',
    icon: <ShieldCheckIcon className='mr-2 size-4' />,
    key: 'security-config',
    modelName: 'securityconfiguration',
  },
  {
    title: 'Anynomisering',
    key: 'anonymization',
    path: Urls.settings.organization.security.anonmyization,
    icon: <FingerPrintIcon className='mr-2 size-4' />,
    modelNames: ['automationconfiguration'],
    modelName: 'tag',
  },
  /* {
    title: 'GDPR',
    key: 'gdpr',
    path: '/gdpr',
    disabled: true,
  },
  {
    title: 'Datahantering',
    key: 'datamanagement',
    path: '/datamanagement',
    disabled: true,
  }, */
] as const satisfies ISubMenu[];

const baseSubMenueItems = [
  {
    title: 'Avtalsuppsägning',
    key: 'contractcancellations',
    path: Urls.settings.modules.base.contractcancellations.settings,
    modelName: 'contractcancellationconfiguration',
  },
  {
    title: 'Svenskt prisbasbelopp',
    key: 'swedishpricebasevalue',
    path: Urls.settings.modules.base.swedishPriceBase,
    modelName: 'swedishpricebasevalue',
  },
  {
    title: 'Referensränta',
    key: 'swedishreferencerate',
    path: Urls.settings.modules.base.swedishReferenceRate,
    modelName: 'swedishreferencerate',
  },
] as const satisfies ISubMenu[];

const invoicingSubMenueItems = [
  {
    title: 'Bokföring',
    key: 'bookkeeping',
    path: Urls.settings.modules.invoicing.accounting.accounts,
    modelName: 'bookkeepingaccountsconfig',
  },
  {
    title: 'Avtalsavisering',
    key: 'contractinvoicing',
    path: Urls.settings.modules.invoicing.invoicingsettings.contractinvoicing,
    modelNames: ['automaticcontractinvoicingsetting', 'paymenttermssetting'],
  },
  {
    title: 'Artiklar',
    key: 'articles',
    path: Urls.settings.modules.invoicing.articles.default,
    modelNames: ['article', 'articlecategory'],
  },
  {
    title: 'Vakansbokning',
    key: 'vacancies',
    path: Urls.settings.modules.invoicing.vacancies,
    modelNames: ['automaticvacancyinvoicingsetting'],
  },
  {
    title: 'Avi-utseende',
    key: 'pdfconfig',
    path: Urls.settings.modules.invoicing.pdfconfig,
    modelName: 'invoicerelatedpdfconfiguration',
  },
  {
    path: Urls.settings.organization.retroactivityconfig.default,
    title: 'Retroaktivitet',
    icon: <RectangleGroupIcon className='mr-2 size-4' />,
    key: 'retroactivity-config',
    modelName: 'retroactivityconfiguration',
  },
  {
    title: 'Meddelanden och bilagor',
    key: 'messages',
    path: Urls.settings.modules.invoicing.contractinvoicingmessages,
    icon: <EnvelopeIcon className='mr-2 size-4' />,
  },
] as const satisfies ISubMenu[];

const moduleSubMenueItems = [
  /* {
    title: 'Mina sidor',
    key: 'mysettings',
    path: '/mysettings',
    icon: <UsersIcon className='mr-2 size-4' />,
    disabled: true,
  }, */
  {
    title: 'Bas',
    key: 'base',
    path: '/base',
    icon: <CubeIcon className='mr-2 size-4' />,
    modelNames: getSubItemsModelNames(baseSubMenueItems),
    subMenus: baseSubMenueItems,
  },
  /* {
    title: 'Hyra',
    key: 'contracts',
    icon: <DocumentCheckIcon className='mr-2 size-4' />,
    path: '/rent',
    disabled: true,
  },
  {
    title: 'Teknisk förvaltning',
    icon: <WrenchIcon className='mr-2 size-4' />,
    key: 'casemanagement',
    path: '/casemanagement',
    disabled: true,
  },
  {
    title: 'Nyckelhantering',
    icon: <KeyIcon className='mr-2 size-4' />,

    key: 'keymanagement',
    path: '/keymanagement',
    disabled: true,
  }, */
  {
    title: 'Avisering & Fakturering',
    icon: <BanknotesIcon className='mr-2 size-4' />,
    key: 'invoicing',
    modelNames: getSubItemsModelNames(invoicingSubMenueItems),
    subMenus: invoicingSubMenueItems,
  },
  /* {
    title: 'Utskick',
    icon: <PaperAirplaneIcon className='mr-2 size-4' />,

    key: 'outgoing',
    path: '/outgoing',
    disabled: true,
  },
  {
    title: 'Insight',
    icon: <MagnifyingGlassIcon className='mr-2 size-4' />,

    key: 'insight',
    path: '/insight',
    disabled: true,
  },
  {
    title: 'Marknad',
    icon: <BuildingStorefrontIcon className='mr-2 size-4' />,
    key: 'market',
    path: '/market',
    disabled: true,
  },
  {
    title: 'Extern förvaltning',
    icon: <BriefcaseIcon className='mr-2 size-4' />,

    key: 'externalmanagement',
    path: '/externalmanagement',
    disabled: true,
  }, */
  // {
  //   title: 'Målgrupper',
  //   icon: <GlobeEuropeAfricaIcon className='mr-2 size-4' />,
  //   key: 'targetaudiences',
  //   path: Urls.settings.organization.market.targetAudiences.apartment.overview,
  //   modelNames: [
  //     'apartmenttargetaudience',
  //     'industrialpremisestargetaudience',
  //     'outdoorsectiontargetaudience',
  //     'parkingspottargetaudience',
  //   ],
  // },
  // {
  //   title: 'Uthyrningsvillkor',
  //   icon: <DocumentTextIcon className='mr-2 size-4' />,
  //   key: 'rentable-terms',
  //   path: Urls.settings.organization.market.terms.apartment.overview,
  //   modelNames: [
  //     'apartmentterms',
  //     'industrialpremisesterms',
  //     'outdoorsectionterms',
  //     'parkingspotterms',
  //   ],
  // },
] as const satisfies ISubMenu[];

export const settingsMenuData = {
  admin: {
    title: 'Admin',
    isIndex: true,
    key: 'admin',
    subMenus: adminSubMenueItems,
  },
  globalconfiguration: {
    title: 'Globala konfigurationer',
    key: 'globalConfiguration',
    subMenus: globalConfigurationsSubMenueItems,
  },
  security: {
    title: 'Säkerhet',
    key: 'security',
    subMenus: securitySubMenuItems,
  },
  modulesettings: {
    title: 'Modulinställningar',
    key: 'modulesettings',
    subMenus: moduleSubMenueItems,
  },
} as const satisfies SettingsMenu;
